<template>
  <b-col
    md="12"
    lg="6"
    cols="12"
  >
    <b-card no-body>
      <b-overlay
        variant="light"
        :show="loadingCustomerLoyal"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div class="flex justify-content-between mb-1 p-1">
          <div class="flex">
            <p class="text-2xl mb-0 font-semibold">
              Customer Loyal</p>
            <img
              id="customerLoyalTooltip"
              class="my-auto ml-[6px]"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            >
            <b-popover
              triggers="hover"
              target="customerLoyalTooltip"
              placement="bottomleft"
            >Customer Loyal merupakan customer yang sering melakukan order.</b-popover>
          </div>
          <b-button
            variant="outline-secondary"
            class="btn-icon rounded-lg p-0"
            size="sm"
            :to="{ name: $route.meta.routeToTopCustomer}"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="25"
            />
          </b-button>
        </div>
        <div
          class="lg:h-[34rem] md:h-[45rem] xl:h-[40rem] px-1"
          style="overflow-y:auto;overflow-x:hidden"
        >
          <b-row
            v-for="(data, index) in customerLoyals"
            :key="index"
            class="border-b-2 py-[5px]"
          >
            <b-col
              cols="1"
            >
              {{ index + 1 }}</b-col>
            <b-col cols="7">
              <p class="text-lg font-semibold mb-0">
                {{ data.name }}</p>
              <p class="mb-0">
                {{ data.location }}</p>
            </b-col>
            <b-col
              cols="4"
              class="text-right"
            >
              <p class="text-lg font-semibold text-success mb-0">
                Rp {{ data.totalDana }}</p>
              <p class="mb-0">
                {{ data.totalProduk }} order</p>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-col>
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('dashboard', [
      'customerLoyals',
      'loadingCustomerLoyal',
    ]),
  },
}
</script>
<style scoped>
.image-product {
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}
</style>
